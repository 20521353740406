// /core/polyfills.js
/* eslint no-extend-native: 0 */
// core-js comes with Next.js. So, you can import it like below
import includes from 'core-js/library/fn/string/virtual/includes';
import repeat from 'core-js/library/fn/string/virtual/repeat';
import assign from 'core-js/library/fn/object/assign';
import arrayIncludes from 'core-js/library/fn/array/includes.js';
import 'core-js/es6/map';
import 'core-js/es6/set';

// Add your polyfills
// This files runs at the very beginning (even before React and Next.js core)

Array.prototype.includes = arrayIncludes;
String.prototype.includes = includes;
String.prototype.repeat = repeat;
Object.assign = assign;
